@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

// @import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&family=Montserrat:wght@700;900&display=swap");

:root {
	--title: #000;
	--title-rgb: 0, 0, 0;
	--text: #515050;
	--body: #ffffff;
	--section: #fbf8f6;
	--dark: #232b32;
	--dark-2: #2e3443;
	--dark-3: #404656;
	--white: #ffffff;
	--base: #fa8d29;
	--btn-hover: rgba(208, 220, 231, 0.1);
	--redish: #f563a9;
	--body-fonts: "Jost", sans-serif;
	--heading-fonts: "Jost", sans-serif;
	--montserrat: "Montserrat", sans-serif;
	--border: rgba(208, 220, 231, 0.15);
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text);
	font-size: 16px;
	line-height: 27.5px;
	font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 42px;
}
h2 {
	font-size: 54px;
	@include breakpoint(max-xl) {
		font-size: 50px;
	}
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 40px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 40px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
@include breakpoint(xl) {
	.container {
		max-width: 1260px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@include breakpoint(max-lg) {
	.container,
	.container-fluid {
		padding-inline: 30px;
	}
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}
.banner-section {
	overflow: hidden;
	padding-top: 100px;
	@include breakpoint(max-lg) {
		padding-bottom: 60px;
	}
	@include breakpoint(max-lg) {
		@include breakpoint(md) {
			padding-top: 140px;
		}
	}
	@include breakpoint(lg) {
		min-height: 100vh;
		.btn-base {
			position: absolute;
			bottom: 60px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 15;
		}
	}
	@include breakpoint(max-md) {
		padding-top: 120px;
		padding-bottom: 130px;
		.btn-base {
			transform: translateY(60px);
		}
	}
}
.text-base {
	color: var(--base) !important;
}
.banner-content {
	position: relative;
	text-align: center;
	.banner_txt {
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translateX(-50%);
		max-width: 100%;
	}
	.txt-grp {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
		.banner_txt {
			width: unset;
		}
	}
	img {
		max-width: 100%;
	}
	transform: translateY(60px);
	.title {
		text-transform: uppercase;
		color: var(--white);
		font-size: 150px;
		font-weight: 900;
		font-family: var(--montserrat);
		margin: 0;
		margin-top: -30px;
	}
	.subtitle {
		color: var(--white);
		text-transform: uppercase;
		font-size: 60px;
		font-family: var(--montserrat);
		font-weight: 700;
		margin: 0;
		position: relative;
		z-index: 1;
	}
	@include breakpoint(max-xl) {
		.txt-grp {
			top: -40px;
			.subtitle {
			}
			.title {
				// font-size: 150px;
			}
		}
	}
	@include breakpoint(max-lg) {
		.txt-grp {
			top: 0px;
			.subtitle {
				font-size: 44px;
			}
			.title {
				font-size: 100px;
			}
		}
	}
	@include breakpoint(max-md) {
		.txt-grp {
			top: -30px;
			.subtitle {
				font-size: 44px;
			}
			.title {
				font-size: 100px;
			}
		}
	}
	@include breakpoint(max-sm) {
		.txt-grp {
			position: relative;
			inset: unset;
			.subtitle {
				font-size: 28px;
				line-height: 1;
				img {
					width: 34px;
				}
				@media (max-width: 374px) {
					font-size: 24px;
				}
			}
			.title {
				line-height: 1;
				font-size: 75px;
				margin: 0;
				margin-bottom: -95px;
				@media (max-width: 400px) {
					font-size: 68px;
					margin-bottom: -75px;
				}
				@media (max-width: 374px) {
					font-size: 58px;
					margin-bottom: -65px;
				}
				@media (max-width: 290px) {
					font-size: 46px;
					margin-bottom: -65px;
				}
			}
		}
	}
	@include breakpoint(lg) {
		transform: translateY(140px);
	}
	@include breakpoint(xl) {
		transform: translateY(160px);
	}
	@include breakpoint(max-xl) {
		max-width: 650px;
		margin: 0 auto;
		.banner_txt {
			max-width: 550px;
			width: 100%;
			@include breakpoint(max-md) {
				max-width: 450px;
			}
		}
	}
	@include breakpoint(max-sm) {
		.banner_thumb {
			width: 80%;
			transform: scale(1.5) translateY(40px);
		}
	}
	@media (max-width: 400px) {
		.banner_thumb {
			transform: scale(1.5) translateY(30px);
		}
	}
	@include breakpoint(md) {
		.inner {
			animation: fadeInUp 2s;
			-webkit-animation: fadeInUp 2s;
			-moz-animation: fadeInUp 2s;
		}
	}
}
.banner_thumb {
	z-index: 9;
	position: relative;
	@include breakpoint(md) {
		animation: zoomIn 2s;
		-webkit-animation: zoomIn 2s;
		-moz-animation: zoomIn 2s;
	}
}
@keyframes fadeInUp {
	0% {
		transform: translateY(100px);
		-webktit-transform: translateY(100px);
		-moz-transform: translateY(100px);
	}
	100% {
		transform: translateY(0);
		-webktit-transform: translateY(0);
		-moz-transform: translateY(0);
	}
}
@keyframes zoomIn {
	0% {
		transform: scale(0.8);
		-webktit-transform: scale(0.8);
		-moz-transform: scale(0.8);
	}
	100% {
		transform: scale(1);
		-webktit-transform: scale(1);
		-moz-transform: scale(1);
	}
}
.btn-base {
	background-color: var(--base);
	height: auto;
	width: auto;
	color: rgb(255, 255, 255);
	text-decoration: none;
	white-space: nowrap;
	min-height: 0px;
	min-width: 0px;
	max-height: none;
	max-width: none;
	text-align: left;
	line-height: 40px;
	letter-spacing: 0px;
	font-weight: 700;
	font-size: 18px;
	border-color: transparent;
	border-style: solid;
	border-width: 2px;
	padding: 10px 56px;
	border-radius: 40px;
	backdrop-filter: none;
	filter: brightness(100%);
	text-transform: uppercase;
	&:hover {
		background: var(--base);
		color: var(--white);
	}
	@include breakpoint(max-sm) {
		font-size: 12px;
		padding: 7px 37px;
		line-height: 33px;
	}
}
.header-section {
	position: fixed;
	left: 0;
	width: 100%;
	top: 26px;
	transition: all ease 0.3s;
	padding: 15px 0;
	z-index: 999;
	&.sticky {
		top: 0;
		background: #fff;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	}
	@include breakpoint(max-md) {
		padding-inline: 20px;
	}
}
.header-section:not(.sticky) {
	.menu li a {
		color: var(--white);
	}
}
.menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 10px;
	column-gap: 28px;
	margin-top: 16px;
	li {
		a {
			text-transform: uppercase;
			color: var(--title);
			font-weight: 600;
			@include breakpoint(max-lg) {
				@include breakpoint(md) {
					font-size: 14px;
				}
			}
		}
	}
	@include breakpoint(max-md) {
		transition: all ease 0.3s;
		&:not(.open) {
			opacity: 0;
			visibility: hidden;
			transform: translateY(-15px);
		}
	}
}
.header-wrapper {
	text-align: center;
	@include breakpoint(max-md) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		.menu {
			width: 100%;
			background: #232323;
			padding: 30px 40px;
			// border-radius: 10px;
			position: absolute;
			top: 100%;
			row-gap: 0;
			li {
				width: 100%;
				font-size: 14px;
				a {
					color: var(--white);
					padding: 20px;
					display: block;
					text-align: left;
					padding-inline: 0;
					font-weight: 500;
				}
				&:not(:last-child) {
					border-bottom: 1px solid rgba(#fff, 0.1);
				}
			}
		}
	}
}
.btn-sm-black {
	background: var(--title) !important;
	color: var(--white) !important;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 5px;
	font-size: 12px;
}
.btn-sm-red {
	background: var(--base) !important;
	color: var(--white) !important;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 5px;
	font-size: 15px;
	@include breakpoint(max-sm) {
	}
}
.btn-md-black {
	background: var(--title) !important;
	color: var(--white) !important;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 35px;
	font-size: 12px;
	padding: 0 23px 0 5px;
}
.pt-120 {
	padding-top: 120px;
	@include breakpoint(max-lg) {
		padding-top: 70px;
	}
}
.pb-120 {
	padding-bottom: 120px;
	@include breakpoint(max-lg) {
		padding-bottom: 90px;
	}
}
@media screen and (min-width: 600px) {
	.pt-large-screen {
		padding-top: 120px;
		@include breakpoint(max-lg) {
			padding-top: 90px;
		}
	}
}
.pt-180 {
	padding-top: 180px;
	@include breakpoint(max-lg) {
		padding-top: 110px;
	}
}
.pb-180 {
	padding-bottom: 180px;
	@include breakpoint(max-lg) {
		padding-bottom: 110px;
	}
}
@media only screen and (min-width: 600px) {
	.pd-pt-180 {
		padding-top: 180px;
		@include breakpoint(max-lg) {
			padding-top: 110px;
		}
	}
}
@media only screen and (min-width: 600px) {
	.pd-pb-180 {
		padding-bottom: 180px;
		@include breakpoint(max-lg) {
			padding-bottom: 110px;
		}
	}
}
.about-content {
	max-width: 700px;
	margin-inline: auto;
	text-align: center;
	.btn {
		margin-bottom: 20px;
	}
	.title {
		margin-bottom: 20px;
	}
	font-size: 18px;
	h6 {
		line-height: 34px;
		color: #515050;
	}
	@include breakpoint(md) {
	}
}
.special-offer-main {
	font-size: 18px;
	max-width: 550px;
	img {
		max-width: 100%;
	}
	@include breakpoint(max-md) {
		h3,
		h4 {
			font-size: 40px;
		}
	}
}
.btn-outline {
	font-weight: 600;
	color: var(--body-clr);
	border: 2px solid var(--title);
	border-radius: 20px;
	padding: 7px 25px;
	&:hover {
		background: var(--title);
		color: var(--white);
	}
}
.offer-item {
	background: var(--section);
	padding: 45px 20px 30px;
	display: block;
	img {
		max-width: 100%;
		margin-bottom: 35px;
	}
	.title {
		a {
			color: var(--title);
		}
		margin-bottom: 16px;
		@include breakpoint(max-lg) {
			font-size: 21px;
			max-width: 170px;
			margin-inline: auto;
		}
	}
	&:not(.text-center) {
		@include breakpoint(md) {
			padding-bottom: 45px;
		}
		@include breakpoint(xl) {
			padding-inline: 45px;
		}
		.title {
			@include breakpoint(max-lg) {
				max-width: 100%;
				font-size: 26px;
			}
		}
	}
	border-radius: 20px;
	@include breakpoint(max-lg) {
	}
}
.container-fluid {
	@include breakpoint(xxl) {
		max-width: 1760px;
		width: 98%;
	}
}
.client-content {
	@include breakpoint(md) {
		font-size: 20px;
		line-height: 1.5;
	}
}
::selection {
	background: var(--base);
	color: var(--white);
}
.slick-dots {
	margin-top: 30px;
	li {
		margin: 0;
		button::before {
			font-size: 10px;
		}
	}
	@include breakpoint(max-lg) {
		bottom: -70px;
	}
}
.clients-say {
	@include breakpoint(max-lg) {
		@include breakpoint(md) {
			.title {
				font-size: 36px;
			}
			.client-content {
				font-size: 18px;
			}
		}
		.about-content {
			padding-inline: 30px;
		}
	}
	padding-bottom: 140px;
	.about-content {
		opacity: 0;
		&.anime {
			opacity: 1;
			animation: flipInX 0.5s;
			-webkit-animation: flipInX 0.5s;
			-moz-animation: flipInX 0.5s;
		}
	}
}
.social-icons {
	display: flex;
	gap: 15px;
	li {
		a {
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}
footer {
	font-size: 19px;
	.logo {
		margin-bottom: 20px;
		img {
			max-width: 100%;
		}
	}
	.footer-widget {
		max-width: 380px;
		width: 100%;
		@include breakpoint(max-xl) {
			max-width: 300px;
		}
		@include breakpoint(max-md) {
			max-width: 100%;
		}
	}
	.addr-area {
		ul {
			padding-top: 15px;
			li {
				line-height: 1.5;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				svg {
					width: 18px;
					color: var(--base);
				}
			}
		}
	}
	.footer-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		row-gap: 55px;
		@include breakpoint(max-md) {
			padding: 30px 30px 60px;
		}
	}
	.btn-base {
		text-transform: uppercase;
	}
	@include breakpoint(max-md) {
		.subtitle {
			font-size: 26px;
		}
	}
}
.subscribe-form {
	.form-control {
		background: var(--section);
		border: none;
		&:focus {
			box-shadow: 0 5px 15px rgba(#000, 0.1);
		}
	}
	.form-control,
	.btn-base {
		width: 100%;
		height: 55px;
		border-radius: 60px;
		box-shadow: none;
		outline: none;
		padding-block: 0;
		padding-inline: 24px;
	}
	.btn-base {
		text-align: center;
		@include breakpoint(max-sm) {
			font-size: 18px;
		}
	}
}
.bg--title {
	background: var(--title);
}
.layer-content {
	h6 {
		color: var(--white);
		margin: 40px 0 35px;
		font-size: 18px;
	}
	.title {
		color: var(--white);
	}
	max-width: 575px;
	@include breakpoint(max-lg) {
		margin-inline: auto;
	}
	.btn-base {
		text-transform: uppercase;
		&:not(:hover) {
			background: transparent;
			border-color: var(--white);
		}
	}
	opacity: 0;
	&.anime {
		opacity: 1;
		animation: flipInX 0.5s;
		-webkit-animation: flipInX 0.5s;
		-moz-animation: flipInX 0.5s;
	}
}
@keyframes flipInX {
	0% {
		transform: skewY(20deg);
	}
	100% {
		transform: skewY(0deg);
	}
}
.pt-max-lg-45px {
	@include breakpoint(max-lg) {
		padding-top: 45px;
	}
}
.container,
.container-fluid {
	> .title {
		@include breakpoint(max-sm) {
			font-size: 52px;
		}
	}
}
@include breakpoint(max-md) {
	.desert-menu-section {
		padding-top: 40px;
	}
	.trending-menu-section {
		padding-top: 140px;
	}
	.layer-section {
		padding: 140px 0 130px;
		.btn-base {
			font-size: 13px;
			padding: 10px 45px;
		}
	}
}
.scroll-top {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: var(--section);
	border: 1px solid var(--border);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
	color: var(--base) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	animation: zoomIn 0.5s;
	-webkit-animation: zoomIn 0.5s;
	-moz-animation: zoomIn 0.5s;
	svg {
		width: 24px;
		height: unset;
	}
}

//CSS za enako velike kartice na mobile view
@media only screen and (max-width: 576px) { // CSS will apply to mobile only
	.card-body {
	  height: 400px; // Make all cards the same height
	}
  
	img {
	//   width: 100%;
	  max-height: 230px;
	  object-fit: scale-down; // Preserve image aspect ratio
	}
}
